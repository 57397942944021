<template>
    <section >
        <b-row >
            <b-col cols="12" md="6">
                <b-card >
                    <div class="col d-flex justify-content-center align-items-center mb-3 mt-1">
                        <span style="font-size: 1.1rem; text-align: center;">
                             <strong v-show="currentHourmeter">⏰ {{ $t('Current hourmeter:') }} {{ currentHourmeter }}</strong>
                             <strong v-show="currentOdometer">📟 {{ $t('Current odometer:') }} {{ currentOdometer }}</strong>
                             <strong v-show="!currentOdometer && !currentHourmeter">{{ $t('No registered maintenance') }}</strong>
                        </span>
                    </div>
                    <app-timeline v-show="maintenancePlan.length > 0">
                        <app-timeline-item v-for="(maintenance, index) in maintenancePlan"
                            :title="`${maintenance.planName} - ${maintenance.name}`"
                            :subtitle="`${$t('Maintenance scheduled for:')}  ${maintenance.hourmeter ? maintenance.hourmeter : maintenance.odometer} ${maintenance.hourmeter ? 'h' : 'Km'}`"
                            :time="`⌛ Faltam ${maintenance.odometerLeft ? maintenance.odometerLeft : maintenance.hourmeterLeft} ${maintenance.hourmeterLeft ? 'h' : 'Km'}`"
                            :icon="index == 0 ? 'AlertCircleIcon' : 'ToolIcon'"
                            :variant="index == 0 ? 'danger' : 'info'"
                        />
                    </app-timeline>
                </b-card>
            </b-col>
            <b-col cols="12" md="6">
                <b-card no-body >
                    <div class="row col d-flex justify-content-center align-items-center mt-2">
                        <h5>{{ $t('Associated Maintenance Plan') }}</h5>
                    </div>
                    <div class="row mb-2">
                        <div class="col d-flex justify-content-center align-items-center">
                            <span style="font-size: 1.1rem; text-align: center;">
                                🎫 {{ $t('Model') }}: <strong class="mr-2">{{ asset.modelIdName }}</strong> {{ $t('Identifier') }}: <strong>{{ asset.plate ? asset.plate : asset.chassisNumber }}</strong>
                            </span>
                        </div>
                        <div class="col-auto ">
                            <b-avatar class="mr-2" @click="openModalAddPlan()" button rounded="sm" variant="primary" size="32px" v-b-tooltip.hover title="Adicionar">
                                <feather-icon icon="PlusCircleIcon" />
                            </b-avatar>
                        </div>
                    </div>
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th class="style-dark">{{ $t('Name') }}</th>
                                <th class="style-dark">{{ $t('Actions') }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="plan in plans" :key="plan.id">
                                <td>{{ plan.name }}</td>
                                <td>
                                    <b-avatar class="float-right" @click="openModalDelete(plan.id, plan.preventiveMaintenancePlanId)" button rounded="sm" variant="danger" size="25px" v-b-tooltip.hover title="Remover">
                                        <feather-icon icon="Trash2Icon" />
                                    </b-avatar>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-if="plans.length == 0">
                            <tr >
                                <td class="text-center col-sm-12 day-table">
                                    <h5>{{ this.$t('No matching records found') }}</h5>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <!-- Section footer table -->
                    <div  class="m-2" v-if="plans.length > 0">
                        <b-row>

                            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                                <span class="text-muted"> {{ $t('Showing')}} {{ from }} {{ $t('to')}} {{to}} {{ $t('of')}} {{ totalItems }} {{ $t('entries')}} </span>
                            </b-col>
                            <!-- Pagination -->
                            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">

                                <b-pagination v-model="currentPage" :total-rows="totalItems" per-page="10" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                                    <template #prev-text>
                                        <feather-icon icon="ChevronLeftIcon" size="18" />
                                    </template>
                                    <template #next-text>
                                        <feather-icon icon="ChevronRightIcon" size="18" />
                                    </template>
                                </b-pagination>

                            </b-col>

                        </b-row>
                    </div>
            </b-card>
            </b-col>
        </b-row>

        <b-modal v-model="showModal" no-close-on-backdrop id="modal-cadastro" size="sm" title="Cadastro" title-class="font-18" hide-footer @hide="limparFormCadastro">
            <div class="row ">
                <div class="col-md-12">
                <p class="sub-header font-weight-bold">{{$t('Choose Maintenance Plan to add Asset')}}</p>
                </div>
            </div>
            <form >
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="descricao"> {{$t('Maintenance Plan')}} </label>
                            <v-select v-model="selectedPlanId" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="allPlansOpt" :reduce="val => val.value" :clearable="false"  />
                        </div>
                    </div>
                </div>
                <div class="form-group text-right m-b-0">
                    <button class="btn btn-primary" type="button" @click="bindPlan()">Adicionar</button>
                </div>
            </form>
        </b-modal>


    </section>
</template>

<script>

import { BTab, BTabs, BCard, BAlert, BLink } from "bootstrap-vue";
import {
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCardText,
    BFormTextarea,
    BNavItemDropdown,
    BDropdownItem,
    BImg,
    BAvatar,
    BModal,
    BPagination
} from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import managementStoreModule from "../managementStoreModule";

import vSelect from 'vue-select'

import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'


export default {
    components: {
        BTab,
        BTabs,
        BCard,
        BAlert,
        BLink,
        BAvatar,
        BPagination,
        BFormInput,
        BFormGroup,
        BForm,
        BRow,
        BCol,
        BButton,
        BCardText,
        BFormTextarea,
        BNavItemDropdown,
        BDropdownItem,
        BImg,
        AppTimeline,
        AppTimelineItem,

        vSelect

    },
    data() {
        return {
            myId: router.currentRoute.params.id,
            asset:{},
            currentPage: 1,
            plans: [],
            totalItems: 0,
            from: 0,
            to: 0,
            perPage: 10,

            showModal: false,

            //allPlansOpt:[],
            selectedPlanId:'',

            maintenancePlan:[],
            currentOdometer:'',
            currentHourmeter:''
           
        };
    },
    created() {
        this.fetchPlans(),
        this.fetchAsset(),
        this.fetchMaintenancePlanSummary()
        
    },
    watch: {
        currentPage() {
            this.fetchPlans()
        }
    },
    methods: {
        fetchMaintenancePlanSummary(){
            store
                .dispatch(`${this.MANAGEMENT_APP_STORE_MODULE_NAME}/fetchMaintenancePlanSummary`, {
                   
                    id: this.myId,
                    

                })
                .then(response => {

                    this.maintenancePlan = response.data.data
                    this.currentHourmeter = response.data.currentHourmeter
                    this.currentOdometer = response.data.currentOdometer
                    

                })
                .catch((e) => {
                    console.log('erro', e)
                })
        },

        bindPlan(){
            this.showModal = false

            store.dispatch(`${this.MANAGEMENT_APP_STORE_MODULE_NAME}/bindMaintenancePlan`, {
                    assetId: this.myId,
                    preventiveMaintenancePlanId: this.selectedPlanId
                })
                .then(response => {
                    this.msgDialog(this.$t('Asset added to maintenance plan'), 'success')
                    this.fetchPlans()
                    this.fetchMaintenancePlanSummary()
                })
                .catch(error => {
                    this.msgDialog(this.$t('An error occurred'), 'error')

                })
        },

        openModalAddPlan(){
            this.showModal = true
        },

        openModalDelete(bindId, planId){
            
            this.$swal({
                title: this.$t('Are you sure?'),
                text: this.$t("You can't revert your action"),
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: this.$t('Yes'),
                cancelButtonText: this.$t('No'),
                showCloseButton: true,
                showLoaderOnConfirm: true,
                customClass: {
                    cancelButton: 'cancelBtn',
                    confirmButton: 'mr-1',
                }
            }).then((result) => {
                if (result.value) {
                    
                    this.deleteBindPlan(bindId, planId)

                } else {}
            })
        
        },

        deleteBindPlan(bindId, planId){
            store.dispatch(`${this.MANAGEMENT_APP_STORE_MODULE_NAME}/deleteBindPlan`, {
                    assetId: this.myId,
                    preventiveMaintenancePlanId: planId,
                    assetMaintenancePlanId: bindId
                })
                .then(response => {
                    this.$swal(this.$t('Deleted'), this.$t('You successfully deleted'), 'success')
                        .then((result) => {
                            if (result.value) {
                                this.fetchPlans()
                                this.fetchMaintenancePlanSummary()

                            } else {}
                        })

                })
                .catch(error => {
                    this.$swal(this.$t('Error'), this.$t('An error occurred while trying to delete'), 'error')
                    console.log(error);
                })
        },

        fetchAllPlans() {

            //Fetch Plans
            store.dispatch(`${this.MANAGEMENT_APP_STORE_MODULE_NAME}/fetchAllMaintenancePlan`, {
                q: '',
                perPage: 100,
                page: 1,
            })
                .then(response => {
                    response.data.data.map(function (v, key) {
                        console.log('fetchAllPlans: ', v.id)
                        allPlansOpt.push({
                            label: v.name,
                            value: v.id
                        },);
                    });

                })
                .catch(error => {

                })
        },

        fetchPlans(){
            store
                .dispatch(`${this.MANAGEMENT_APP_STORE_MODULE_NAME}/fetchPlansByAssetId`, {
                   
                    assetId: this.myId,
                    page: this.currentPage,
                    perPage: 10

                })
                .then(response => {
                    this.plans = response.data.data
                    if(response.data.length) this.totalItems = response.data.totalItems

                    const localItemsCount = this.plans ? response.data.data.length : 0
                    const perPage = 10
                    this.from = perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0)
                    this.to = perPage * (this.currentPage - 1) + localItemsCount

                })
                .catch((e) => {
                    console.log('erro', e)
                })
        },

        fetchAsset() {
            store
                .dispatch(`${this.MANAGEMENT_APP_STORE_MODULE_NAME}/fetchAsset`, {
                    id: this.myId,
                })
                .then(response => {
                    this.asset = response.data

                })
                .catch(() => {
                    console.log('erro')
                })
        },
        

        msgDialog(msg, icon) {
            this.$swal({
                position: "top-end",
                icon: icon,
                title: msg,
                showConfirmButton: false,
                timer: 1500 * 3,
                customClass: {
                    confirmButton: "btn btn-primary",
                },

                buttonsStyling: false,
            });
        },

        limparFormCadastro(){
            this.selectedPlanId = ''
        }

        
    },
    setup() {

        const MANAGEMENT_APP_STORE_MODULE_NAME = "app-management";

        const allPlansOpt = ref([])

        // Register module
        if (!store.hasModule(MANAGEMENT_APP_STORE_MODULE_NAME))
            store.registerModule(
                MANAGEMENT_APP_STORE_MODULE_NAME,
                managementStoreModule
            );

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(MANAGEMENT_APP_STORE_MODULE_NAME))
                store.unregisterModule(MANAGEMENT_APP_STORE_MODULE_NAME);
        });

        //Fetch Plans
        store.dispatch(`app-management/fetchAllMaintenancePlan`, {
                q: '',
                perPage: 100,
                page: 1,
            })
                .then(response => {
                    response.data.data.map(function (v, key) {
                        console.log('fetchAllPlans: ', v.id)
                        allPlansOpt.value.push({
                            label: v.name,
                            value: v.id
                        },);
                    });

                })
                .catch(error => {

                })

        

        return {
            MANAGEMENT_APP_STORE_MODULE_NAME,
            allPlansOpt
        };
    },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

</style>
<style>
.title-head {
    margin-bottom: 32px;
}
</style>
